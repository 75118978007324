.blink-error {
    font-weight: bold;
    animation: blinkError 3s infinite;
    padding: 10px;
    border-radius: 5px;
}

.blink-success {
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
    animation: blinkSuccess 2s infinite;
    padding: 10px;
    border-radius: 5px;
    color: white;
    background: #4caf50;
    text-align: right;
}

.action-button {
    margin-left: 10px!important;
    min-width: 180px!important;
}
.action-button:hover {
    color: white;
}

.success-result-row {
    margin-bottom: 20px!important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 550px) {
    .success-result-row {
        justify-content: flex-start;
    }
}

@keyframes blinkError {
    0%, 20% {
        color: white;
        background: #e53935;
    }
    21%, 80% {
        color: #e53935;
        background: white;
    }
    81%, 100% {
        color: white;
        background: #e53935;
    }
}

@keyframes blinkSuccess {
    0%, 20% {
        color: white;
        background: #4caf50;
    }
    21%, 80% {
        color: #4caf50;
        background: white;
    }
    81%, 100% {
        color: white;
        background: #4caf50;
    }
}