/** Form Input Mixin **/
@mixin formInput {
  width: 22rem;
  height: 2.5rem;
  outline: none;
  border-radius: 3rem;
  padding: 0 1rem;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

/** Login Page **/
.loginPage {
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/** Copyright **/
.copyright3 {
  opacity: 0.6;
  font-size: 0.8rem;
  // position: absolute;
  // left: 1rem;
  // bottom: 1rem;
  a {
    text-decoration: none;
  }
}

/** Login section **/
.login-section {
  // width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;

  h2 {
    margin-bottom: 3rem;
  }
}

/** Form styling **/
.form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-email {
  @include formInput();
}

.form-pwd {
  @include formInput();
}

.form-first-name {
  @include formInput();
}

.form-last-name {
  @include formInput();
}

.form-email:focus,
.form-pwd:focus {
  border: 2px solid #ccc;
}

.form-email.invalid,
.form-pwd.invalid,
.form-first-name.invalid,
.form-last-name.invalid {
  border: 2px solid rgb(207, 2, 2);
}

.submitBtn {
  margin-top: 1rem;
  width: 22rem;
  height: 2.5rem;
  border-radius: 3rem;
  cursor: pointer;
  background: rgb(76, 76, 252);
  border: none;
  color: white;
}

.submitBtn:hover {
  background: blue;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #ccc;
  cursor: not-allowed;
}

.span-switch {
  cursor: pointer;
  color: blue;
}

.span-switch:hover {
  text-decoration: underline;
}
